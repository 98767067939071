import { useAuth } from '@pesto/features';
import type { ExpandableMenuList, HomeLink } from '@pesto/ui/components/Navigation/Navigation.types';
import { ApplicationLayout } from '@pesto/ui/layouts/ApplicationLayout';
import { useTheme } from '@pesto/ui/providers/ThemeProvider';
import { humanize } from '@pesto/utils';
import { CreditCard, Home, LineChart, Package, ShoppingCart } from 'lucide-react';
import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { routes } from '../constants/routes';

export interface ApplicationLayoutProps {
  className?: string;
  children?: ReactNode;
  component?: ReactNode;
}

export const PartnerApplicationLayout = (props: ApplicationLayoutProps) => {
  const { children } = props;
  const { signOut } = useAuth();
  const { setTheme, setColorScheme, availableColorSchemes } = useTheme();

  const userMenuItems: ExpandableMenuList = [
    { type: 'label', label: 'My Account' },
    { type: 'separator' },
    { type: 'item', label: 'Settings', onClick: () => console.log('Settings clicked') },
    { type: 'item', label: 'Support', onClick: () => console.log('Support clicked') },
    {
      type: 'sub',
      label: 'Theme',
      items: [
        { type: 'item', label: 'Light', onClick: () => setTheme('light') },
        { type: 'item', label: 'Dark', onClick: () => setTheme('dark') },
        { type: 'item', label: 'System', onClick: () => setTheme('system') },
      ],
    },
    {
      type: 'sub',
      label: 'Color Scheme',
      items: availableColorSchemes.map(scheme => ({
        type: 'item',
        label: humanize(scheme),
        onClick: () => setColorScheme(scheme),
      })),
    },
    { type: 'separator' },
    { type: 'item', label: 'Logout', onClick: () => signOut() },
  ];

  const menuLinks: ExpandableMenuList = [
    {
      type: 'item',
      href: routes.dashboard,
      label: 'Dashboard',
      icon: Home,
    },
    {
      type: 'item',
      href: routes.creditApplications,
      label: 'Credit Applications',
      icon: ShoppingCart,
      count: 6,
    },
    {
      type: 'item',
      href: routes.collateral,
      label: 'Collateral',
      icon: Package,
    },
    {
      type: 'item',
      href: routes.customers,
      label: 'Customers',
      icon: LineChart,
    },
    {
      type: 'item',
      href: routes.analytics,
      label: 'Analytics',
      icon: Home,
    },
  ];

  const homeLink: HomeLink = {
    name: 'Partner Experience',
    href: routes.dashboard,
    icon: CreditCard,
  };

  // change to !user
  const location = useLocation();
  if (location.pathname === '/') {
    return <>{children}</>;
  }

  return (
    <ApplicationLayout userMenuItems={userMenuItems} menuLinks={menuLinks} homeLink={homeLink}>
      {children}
    </ApplicationLayout>
  );
};
