import { useApplicationActions } from '@pesto/hooks/src/useApplicationActions';
import type { DropdownOption } from '@pesto/ui/components/DropdownInput';
import { Modal } from '@pesto/ui/components/Modal';
import { CreditApplicationDetailScreen } from '@pesto/ui/features/CreditApplication/CreditApplicationDetailScreen';
import { CreditCheckContent } from '@pesto/ui/features/CreditApplication/components/CreditCheckContent';
import { OverviewDetails } from '@pesto/ui/features/CreditApplication/components/OverviewDetails';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useApproveManualReviewMutation } from '../../../../mission-control/src/__generated__/graphql/api';
import { AssetType, useGetCreditApplicationByIdQuery } from '../../__generated__/graphql/api';
import { MockCreditApplication } from '../../__generated__/graphql/mocks';
import { UserDetails } from '../../components/UserDetails/UserDetails';
import { routes } from '../../constants/routes';

import { AdminActionRecordsContent } from './components/AdminActionRecordsContent';
import { ApplicationDetailsView } from './components/ApplicationDetailsView';
import { DocCheckContent } from './components/DocCheckContent';
import { useApplicationData } from './hooks/useApplicationData';
import { ApplicationActions, useApplicationModal } from './hooks/useApplicationModal';
import { useCreditApplicationDetails } from './hooks/useCreditApplicationDetails';

export const addBasicItemsOptions: DropdownOption[] = [
  { value: AssetType.Jewelry, label: 'Jewelry' },
  { value: AssetType.PreciousMetals, label: 'Precious Metal' },
  { value: AssetType.Gemstones, label: 'Gemstones' },
  { value: AssetType.Other, label: 'Other' },
];

const mockApplication = MockCreditApplication();

export const CreditApplicationDetail = () => {
  const [filterInactive, setFilterInactive] = useState(true);

  const params = useParams();

  const isMock = params?.id === 'mock';

  const {
    data: queryData,
    loading,
    refetch,
  } = useGetCreditApplicationByIdQuery({
    variables: { creditApplicationId: params?.id ?? '' },
    fetchPolicy: 'cache-and-network',
  });

  const application = isMock ? mockApplication : queryData?.creditApplication;

  const items = filterInactive ? application?.items?.filter(item => item?.active === true) : application?.items;
  const inNoActiveItems = application?.items?.filter(item => item?.active === false);

  const { showModal, action, setShowModal, setAction, getModalTitle, modalContent } = useApplicationModal({
    application,
  });

  const { mainActionButtonsRender } = useCreditApplicationDetails();
  const { getApplicationData } = useApplicationData({ application, setShowModal, setAction });

  const { executeAction, isLoading: actionLoading } = useApplicationActions({
    [ApplicationActions.APPROVE]: useApproveManualReviewMutation(),
    // [ApplicationActions.CREATE_LOG]: useCreateActionRecordForCurrentAdminMutation(),
    // [ApplicationActions.CLOSE_APPLICATION]: useCloseApplicationMutation(),
    // complete the rest when we will have mutations
  });

  const handleModalConfirm = useCallback(async () => {
    if (action) {
      const success = await executeAction(action);
      if (success) {
        setShowModal(false);
        if (action === ApplicationActions.CREATE_LOG) {
          refetch();
        }
      }
    }
  }, [action, executeAction, refetch, setShowModal]);

  return (
    <div>
      <CreditApplicationDetailScreen
        breadcrumb={{
          items: [
            { href: routes.dashboard, label: 'Dashboard' },
            { href: routes.creditApplications, label: 'Credit Applications' },
            { label: `${application?.id}` },
          ],
        }}
        tabs={[
          {
            label: 'Application view',
            value: 'application',
            content: (
              <div className="flex flex-col gap-4">
                <OverviewDetails
                  actionButtons={mainActionButtonsRender()}
                  applicationData={getApplicationData()}
                  onActionButtonClick={action => {
                    setAction(action);
                    setShowModal(true);
                  }}
                />
                <ApplicationDetailsView
                  items={items}
                  inNoActiveItems={inNoActiveItems}
                  filterInactive={filterInactive}
                  handleSetFilterInactive={() => setFilterInactive(!filterInactive)}
                  canAddCollateral={true}
                  navigate={() => {}}
                  application={application}
                  refetch={() => {}}
                  setAction={setAction}
                  setShowModal={setShowModal}
                  setPaymentId={() => {}}
                />
              </div>
            ),
          },
          { label: 'User view', value: 'user', content: <UserDetails userId={application?.pestoUser?.id} /> },
          {
            label: 'Admin Action Records',
            value: 'adminActionRecords',
            amount: application?.adminActionRecords?.length,
            content: <AdminActionRecordsContent application={application} />,
          },
          {
            label: 'Credit Check',
            value: 'creditCheck',
            amount: application?.creditChecks?.length,
            content: <CreditCheckContent title="Credit Checks" checks={application?.creditChecks} />,
          },
          {
            label: 'Doc Check',
            value: 'docCheck',
            amount: application?.docChecks?.length,
            content: <DocCheckContent checks={application?.docChecks} title="Doc Checks" />,
          },
        ]}
        loading={loading}
      />

      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title={getModalTitle()}
        onConfirm={handleModalConfirm}
        loading={actionLoading}
      >
        <div>{modalContent()}</div>
      </Modal>
    </div>
  );
};
